import React from 'react';
import './Contact.scss';
import SuperForm from '../SuperForm/SuperForm';

function Contact() {
  return (
    <div className="container contact_container">
      <div className="row">
        <div className="col-lg-6 col-12 contact_phrase">
        <h5>Fale Conosco</h5>
        <h6>Entre em contato com a gente.</h6>
        <p>Nossa equipe adora falar do Listo 360 =)</p>
        </div>
        <div className="col-lg-6 col-12 contact_capture">
          <SuperForm />
        </div>
      </div>
    </div>
  );
}

export default Contact;