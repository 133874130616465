import React from 'react';
import './Intro.scss';

import Navbar from '../Navbar/Navbar';

import { Link, animateScroll as scroll } from "react-scroll";

import devices from '../../assets/devices-min.png';
import gota from '../../assets/gota-min.png';


function Intro() {
  return (
  <div className="introComp">
    <Navbar/>
    <div className="container"> 
      <div className="row">
        <div className="col-lg-5 col-12 routine">
          <div className="main_text">
            <h1>ROTINAS E
                CHECKLISTS
                SIMPLIFICADOS
            </h1>
            <h2>O Listo 360 possibilita automatizar e tornar digital a jornada das áreas do seu negócio que dependem de rotinas e checklists.</h2>
          </div>
          <div className="demo">
          <Link
          className="demo_btn"
          activeClass="active"
          to="contact_container"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
          >SOLICITE UMA DEMO</Link>
          </div>
          <div className="ver_mais">
          <Link
          className="ver_mais_btn"
          activeClass="active"
          to="conceptListo360"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
          >VER MAIS</Link>
          </div>
          <div className="gota">
            <img src={gota}/>
          </div>
        </div>
        <div className="col-lg-7 col-12 devices">
          <img src={devices}/>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Intro;
