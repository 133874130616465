import React from "react";

import useFormValidation from "../../helpers/useFormValidation";
import validateAuth from "../../helpers/validateAuth";

const INITIAL_STATE = {
  subject: "",
  name: "",
  email: "",
  message_html: "",
  sending: "",
  sent: ""
};

function Register() {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting,
    isSubmitted
  } = useFormValidation(INITIAL_STATE, validateAuth);

  return (
    <form className="contact_form_listo360" onSubmit={handleSubmit}>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <input
         onChange={handleChange}
         //onBlur={handleBlur}
         name="subject"
         value={values.subject}
         id="input_form"
         className={errors.subject && "error-input"} 
         autoComplete="off"
         placeholder="Assunto"
          />
          <span className="bar"></span>
        {errors.subject && <p className="error-text">{errors.subject}</p>}
        </div>
        <div className="col-12">
          <input
         onChange={handleChange}
         //onBlur={handleBlur}
         name="name"
         value={values.name}
         id="input_form"
         className={errors.name && "error-input"} 
         autoComplete="off"
         placeholder="Nome"
          />
          <span className="bar"></span>
         {errors.name && <p className="error-text">{errors.name}</p>}
        </div>
        <div className="col-12">
          <input
         onChange={handleChange}
         //onBlur={handleBlur}
         name="email"
         value={values.email}
         id="input_form"
         className={errors.email && "error-input"} 
         autoComplete="off"
         placeholder="E-mail"
          />
          <span className="bar"></span>
         {errors.email && <p className="error-text">{errors.email}</p>}
        </div>
        <div className="col-12">
          <textarea
            onChange={handleChange}
            //onBlur={handleBlur}
            name="message_html"
            value={values.message_html}
            id="text_form"
            className={errors.message_html && "error-input"} 
            autoComplete="off"
            placeholder="Mensagem"
            style={{width: '100%', height: '150px'}}
          />
          <span className="bar"></span>
          {errors.message_html && <p className="error-text">{errors.message_html}</p>}
        </div>
      </div>
    </div>
      <input value="ENVIAR" className="btn btn--submit" disabled={isSubmitting} type="submit" />
      {isSubmitting && <p className="sending-msg">Enviando..... </p>}
      {isSubmitted && <p className="sent-msg">Obrigado! Em breve entraremos <br></br> em contato =) </p>}
    </form>
    
  );
}

export default Register;