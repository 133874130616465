import React from 'react';
import './Use.scss';
import applications from '../../assets/aplicacoes-min.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faMugHot } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";




function Use() {
  return (
    <div className="useComp">
      <div className="container"> 
        <div className="row">
          <div className="col-lg-6 col-12 flexibility">
              <h3>Flexibilidade de uso</h3> <br></br> 
              <h4>O Checklist ideal para seu negócio</h4>
              <p>
              A <strong>Listo 360</strong> foi desenvolvida seguindo o conceito <strong> SaaS (<i>Software as a Service</i>)</strong>, que permite a constante atualização da solução, sem os altos custos de licença de uso e a cobrança de mensalidade de acordo com a sua necessidade.
              </p>
              <p>
              Isso é possível graças ao uso de <strong><i>Cloud Computing</i> (Computação na Nuvem)</strong>, que permite acessar nossas ferramentas a qualquer hora, em qualquer lugar e dispositivo.
              </p>
              <p>
              Como todo o processamento e armazenamento ocorre em nossos servidores, <strong>você não precisa se preocupar com equipamentos e configurações.</strong>
              </p>
          </div>
          <div className="col-lg-6 col-12 applications">
          <img src={applications}/> 
          </div>
        </div>
        <div className="row">
            <div className="col-lg-3 col-md-6 use_Listo360">
            <div className="use_img">
            <FontAwesomeIcon icon={faBuilding} />
            </div>
            <div>
            <h5>Facilities</h5>
            </div>
            <div>
            <p>Crie rotinas de inspeção e limpeza. Defina as equipes, associe rotinas, defina periodicidade e receba relatórios em tempo real.​</p>
            </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 use_Listo360">
            <div className="use_img">
            <FontAwesomeIcon icon={faMugHot} />
            </div>
            <div>
            <h5>Restaurante</h5>
            </div>
            <div>
            <p>Organize o estoque, crie rotinas de limpeza e manutenção, controle ocorrências, envie notificações diretamente para os funcionários e receba feedback dos clientes.​</p>
            </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 use_Listo360">
            <div className="use_img">
            <FontAwesomeIcon icon={faGlobe} />
            </div>
            <div>
            <h5>Hotéis</h5>
            </div>
            <div>
            <p>Organize reservas, checkins e checkouts, agendas, rotinas de limpeza e manutenção, ocorrências, notificações, unidades, pessoas, feedbacks e expectativas.</p>
            </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 use_Listo360">
            <div className="use_img">
            <FontAwesomeIcon icon={faLayerGroup} />
            </div>
            <div>
            <h5>Gestão de Projetos</h5>
            </div>
            <div>
            <p>Cadastre projetos, atividades, equipes, indicadores de sucesso, e acompanhe a evolução do grupo e individualmente em tempo real. Perfeito para metodologia ágil!​</p>
            </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-3 col-md-6 col-12 use_Listo360">
            <div className="use_img">
            <FontAwesomeIcon icon={faTruck} />
            </div>
            <div>
            <h5>Delivery</h5>
            </div>
            <div>
            <p>Monte rotinas de entregas, garanta todos os itens com checklists, confronte enviados e recebidos com evidências fotográficas e garanta a entrega correta com o QR Code.</p>
            </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 use_Listo360">
            <div className="use_img">
            <FontAwesomeIcon icon={faHome} />
            </div>
            <div>
            <h5>Construção Civil</h5>
            </div>
            <div>
            <p>Garanta que os materiais foram entregues no local correto, que a equipe certa foi escalada, que as ferramentas certas estão em uso, e acompanhe o andamento da obra.​</p>
            </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 use_Listo360">
            <div className="use_img">
            <FontAwesomeIcon icon={faUserFriends} />
            </div>
            <div>
            <h5>Equipe Comercial</h5>
            </div>
            <div>
            <p>Estabeleça rotinas de contato, reuniões, acompanhamentos e vendas. Garanta que nenhuma atividade ou produto seja esquecido ao utilizar checklists.​</p>
            </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 use_Listo360">
            <div className="use_img">
            <FontAwesomeIcon icon={faCog} />
            </div>
            <div>
            <h5>Manutenção</h5>
            </div>
            <div>
            <p>Configure rotinas de manutenções preventivas programadas, faça rondas de inspeção, receba ordens de serviço e feedback sobre as tarefas executadas.</p>
            </div>
            </div>
        </div>
         
        </div>
    </div>
  );
}

export default Use;