import React from 'react';
import './Concept.scss';
import confidence from '../../assets/confiabilidade-min.png';
import velocity from '../../assets/velocidade-min.png';
import eficiency from '../../assets/eficiencia-min.png';


function Concept() {
  return (
  <div className="conceptComp">
    <div className="container"> 
      <div className="row">
          <div className="col-12 conceptListo360">
            <h3>Tripé Digital</h3>
            <h4>Os Conceitos do Listo360</h4>
            <p>
              Mesmo que você não perceba: qualquer sequência de ações pode ser convertida em rotinas e checklists. Porém, apenas criar as rotinas e checklists sem visão estratégica não é suficiente para atingir seus objetivos! Acreditamos em um tripé digital baseado em:
            </p>
          </div>
      </div>
      <div className="row tripod_row">
        <div className="col-md-4 col-12 tripod_Listo360">
              <img src={confidence}/>
              <p>Confiabilidade</p>
              <span>Identifique com precisão e assertividade as pessoas, locais, atividades, checklists, rotinas e seus andamentos em tempo real.</span>
        </div>
        <div className="col-md-4 col-12 tripod_Listo360">
              <img src={velocity}/> 
              <p>Velocidade</p>
              <span>Maior velocidade na abertura e conclusão de chamados, além de tornar mais rápidas as tomadas de decisão e realocação de equipes.</span>
        </div>
        <div className="col-md-4 col-12 tripod_Listo360">
              <img src={eficiency}/>
              <p>Eficiencia</p>
              <span>O Listo360 aumenta a produtividade, entrega relatórios em tempo real, possibilita redução de custos e permite a prática de visão de dono.</span>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Concept;
