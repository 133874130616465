import React from 'react';

import logo from '../../assets/logo_small.png';

import { Link, animateScroll as scroll } from "react-scroll";


function Navbar() {
  return (
    <nav className="navbar fixed-top transparent navbar-expand-lg navbar-light">
      <div className="container">
    <a className="navbar-brand" href="#"> <img src={logo}/> </a>
    <button className="navbar-toggler" id="nav-icon1" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span></span>
      <span></span>
      <span></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link
          activeClass="active"
          to="conceptListo360"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
          >Conceito</Link>
        </li>
        <li className="nav-item">
        <Link
          activeClass="active"
          to="digitalListo360"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
          >Digitilização</Link>
        </li>
        <li className="nav-item">
        <Link
          activeClass="active"
          to="flexibility"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
          >Usos</Link>
        </li>
        <li className="nav-item">
        <Link
          activeClass="active"
          to="newsletter"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
          >Newsletter</Link>
        </li>
        <li className="nav-item">
        <Link
          activeClass="active"
          to="contact_container"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
          >Contato</Link>
        </li>
      </ul>
    </div>
    </div>
  </nav>
  );
}

export default Navbar;
