export default function validateAuth(values) {
    let errors = {};
    // Subject Errors
    if (!values.subject) {
      errors.subject = "Assunto é obrigatório.";
    }
    // Name Errors
    if (!values.name) {
    errors.name = "Nome é obrigatório.";
    }
    // Email Errors
    if (!values.email) {
      errors.email = "E-mail é obrigatório";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Por favor digite um e-mail válido.";
    }
    // Message Errors
    if (!values.message_html) {
        errors.message_html = "Por favor escreva uma mensagem.";
        } else if (values.message_html.length < 6) {
        errors.message_html = "Por favor seja um pouco mais específico.";
        }
    return errors;
  }
  