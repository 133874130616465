import React from 'react';
import './Footer.scss';

import Newsletter from '../Newsletter/Newsletter';
import Contact from '../Contact/Contact';

import { Link, animateScroll as scroll } from "react-scroll";

import logo from '../../assets/logo_small.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";



function Footer() {
  return (
    <div className="footer">
        < Newsletter/>
        < Contact/>
        <div className="container footer_container">
          <div className="row">
            <div className="col-12 separator">
              <hr></hr>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-12 footer_col">
            <img src={logo}/>
            <div className="direitos">
              <p>2019 Todos os direitos reservados</p>
            </div>
            </div>
            <div className="col-md-4 col-12 footer_col">
              <div className="contact_span">
              <span>Contato</span>
              </div>
              <div>
                <ul>
                  <li style={{cursor: 'pointer'}}>
                  <Link
                  className="email"
                  activeClass="active"
                  to="contact_container"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration= {500}
                  >contato@listo360.com.br</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-12 footer_col">
            <a href="https://www.linkedin.com/company/listo360" target="_blank">
            <FontAwesomeIcon icon={faLinkedinIn} />
            </a>  
            <a href="https://www.facebook.com/listo360"
            target="_blank">
            <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://www.instagram.com/listo_360/"
            target="_blank">
            <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://www.youtube.com/channel/UCZMPyjE1mP8p2VUN1uD1IUQ/" target="_blank">
            <FontAwesomeIcon icon={faYoutube} />
            </a>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Footer;