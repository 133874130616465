import React from "react";

function useFormValidation(initialState, validate) {
  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isSubmitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        setTimeout(() => {
          console.log("validated!", values.subject, values.name, values.email, values.message_html);
          const templateId = 'template_nIgzLyuK';
          sendFeedback(templateId, {subject: values.subject, name: values.name, email: values.email, message_html: values.message_html });
          setValues(initialState);
          setSubmitting(false);
          setSubmitted(true);
        }, 2000);
      } else {
        setSubmitting(false);
      }
    }
  }, [errors]);

  function handleChange(event) {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }

  function handleBlur() {
    const validationErrors = validate(values);
    setErrors(validationErrors);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    setSubmitting(true);
  }

  function sendFeedback (templateId, variables) {
    window.emailjs.send(
      'outlook', templateId,
      variables
      ).then(res => {
        console.log('Email successfully sent!')
      })
      .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
  }

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting,
    isSubmitted
  };
}

export default useFormValidation;







  
  



