import React from 'react';
import './App.scss';

import Intro from '../Intro/Intro';
import Concept from '../Concept/Concept';
import Digital from '../Digital/Digital';
import Use from '../Use/Use';
import Footer from '../Footer/Footer';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";

function App() {
  return (
    <div className="appComp">
      < Intro/>
      < Concept/>
      < Digital/>
      < Use/>
      < Footer/>
      <a href="#" className="scrollToTop" 
      style={{display: 'none'}}>
        <FontAwesomeIcon icon={faAngleDoubleUp} />
      </a>
    </div>
  );
}

export default App;
