import React from 'react';
import './Newsletter.scss';
import Mailchimp from 'react-mailchimp-form'



function Newsletter() {
  return (
    <div className="newsletter">
        <div className="container newsletter_container">
            <div className="row">
              <div className="col-12 newsletter_listo360">

                <div className="newsletter_form">
                <h5>Newsletter</h5>
                <h6>Receba nossas novidades.</h6>
                  <Mailchimp
                    action='https://verity.us20.list-manage.com/subscribe/post?u=b3ff2c0afa3b2fe6f17e0bba5&amp;id=9d1da8dcf5'
                    fields={[
                      {
                        name: 'EMAIL',
                        placeholder: 'E-mail',
                        type: 'email',
                        required: true
                      }
                    ]}
                    messages = {
                      {
                        sending: "Enviando.....",
                        success: "Obrigado por inscrever-se em nossa newsletter! Entraremos em contato em breve.",
                        error: "Ocorreu um erro interno inesperado.",
                        empty: "Você deve escrever um e-mail.",
                        duplicate: "Muitas tentativas de inscrição para este endereço de email",
                        button: "INSCREVER-SE"
                      }
                    }

                    className='mailchimp_form'
                  />
                  <span className="bar"></span>
                </div>
              
              </div>
            </div>
        </div>
    </div>
  );
}

export default Newsletter;