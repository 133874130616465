import React from 'react';
import './Digital.scss';

import identity from '../../assets/identificacao-min.png';
import ease from '../../assets/facilidade-min.png';
import inspection from '../../assets/inspecoes-min.png';
import chamado from '../../assets/chamados-min.png';
import decisions from '../../assets/decisoes-min.png';
import report from '../../assets/relatorios-min.png';
import audit from '../../assets/auditoria-min.png';
import savings from '../../assets/economia-min.png';


function Digital() {
  return (
    <div className="digitalComp">
        <div className="container"> 
          <div className="row">
            <div className="col-12 digitalListo360">
            <h5>Digitalização da Jornada</h5>
              <h6>A jornada na palma da mão</h6>
              <p>
              A experiência e satisfação do seu cliente a um clique dos seus checklists de rotina.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 col-12 features_Listo360">
              <div>
              <img src={identity}/>
              </div>
              <div>
              <span className="first">Identificação</span>
              </div>
              <div>
              <span className="second">Confiável</span> 
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 features_Listo360">
            <div>
              <img src={ease}/>
              </div>
              <div>
              <span className="first">Facilidade</span>
              </div>
              <div>
              <span className="second">de uso</span> 
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 features_Listo360">
            <div>
              <img src={inspection}/>
              </div>
              <div>
              <span className="first">Inspeções</span>
              </div>
              <div>
              <span className="second">Programadas</span> 
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 features_Listo360">
            <div>
              <img src={chamado}/>
              </div>
              <div>
              <span className="first">Chamados</span>
              </div>
              <div>
              <span className="second">Digitalizados</span> 
              </div>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-3 col-md-6 col-12 features_Listo360">
            <div>
              <img src={decisions}/>
              </div>
              <div>
              <span className="first">Decisões</span>
              </div>
              <div>
              <span className="second">Holísticas</span> 
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 features_Listo360">
            <div>
              <img src={report}/>
              </div>
              <div>
              <span className="first">Relatórios</span>
              </div>
              <div>
              <span className="second">Real-time</span> 
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 features_Listo360">
            <div>
              <img src={audit}/>
              </div>
              <div>
              <span className="first">Auditoria</span>
              </div>
              <div>
              <span className="second">e Avaliações</span> 
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 features_Listo360">
            <div>
              <img src={savings}/>
              </div>
              <div>
              <span className="first">Economia</span>
              </div>
              <div>
              <span className="second">Tempo/Custo</span> 
              </div>
            </div>
        </div>
      </div>
   </div>
  );
}

export default Digital;